.bannerContent {
    width: 450px;
    background-color: rgba(255, 255, 255, 0.83);
    border-radius: 16px;
    font-size: 18px;
    padding: 30px 16px;
}

.loginBtn {
    background-color: #58b1b9;
    color: #fff;
}

.flexRow {
    display: -webkit-flex;
    /* Safari */
    -webkit-flex-direction: row;
    /* Safari 6.1+ */
    display: flex;
    flex-direction: row;
}

.bannerImg {
    width: 100%;
}

.section_1_title {
    width: 100%;
    text-align: center;
    color: #58b1b9;
    line-height: 56px;
    font-size: 32px;
    font-weight: bold;
}

.client_additional_logo {
    margin: 0 auto;
}


.step {
    min-height: 330px;
    background-color: #E8F9FA;
    border-radius: 11px;
    position: relative;
}

.step img {
    width: 180px;
    margin: 0 auto;
    display: block;
}

.step p {
    min-height: 80px;
    font-size: 14px;
}

.stepTittle {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}


.body_end_text {
    width: 60%;
    text-align: center;
    color: #58b1b9;
    line-height: 56px;
    margin: 0 auto 56px auto;
    font-size: 32px;
}

.Footer {
    width: 100%;
    position: relative;
    background-color: #343A40;
}

.footerImg {
    width: 258px;
    height: 78px;
}

.poweredText {
    width: 258px;
    line-height: 30px;
    font-size: 16px;
    color: #fff;
}

.footerHrefBody {
    width: 240px;
    line-height: 25px;
    font-size: 14px;
    color: #fff;
}

.footerHrefBody a {
    color: #fff;
    cursor: pointer;
}