button {
    border: none;
}

.content-Mytabs {
    flex-grow: 1;
}

.content-t {
    width: 100%;
    height: 100%;
    display: none;
}
.content-t::-webkit-scrollbar{
    display:none;
}

    .content-t h2 {
        padding: 0px 0 5px 0px;
    }


    .content-t p {
        width: 100%;
        height: 100%;
    }

.active-content-t {
    display: block;
}
.mb-0::-webkit-scrollbar{
    display:none;
}
.content-t p::-webkit-scrollbar{
    display:none;
}
.Mytabs::after{
    width:100% !important;
}
.icon1{
    width: 2rem !important;
    height: 2rem !important;
    margin: 0 0.7rem !important;
}
.media{
    align-items: flex-start !important;
}
.card-body::-webkit-scrollbar{
    display:none;
}
.BuyNow-btn{
    background-image:url('../../../assets/img/icons/shopButton.svg');
    background-repeat: round;
    width: 9.5rem;
    height: 2.5rem;
}
.iconContent{
    display: flex;
    flex-direction: column;
}
.screenicon{
    background-image:url('../../../assets/img/icons/screen.svg');
    background-repeat: no-repeat;
    width: 2.4rem;
    height: 2.4rem;
}
.loading{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    border-radius: 1.2rem;
    background: #fff;
    min-height: 40rem;
    opacity: 0.8;
}
.tableCard{
    height:57rem;
    overflow:scroll;
}
.tableCard::-webkit-scrollbar{
    display:none;
}
.scrollbar1::-webkit-scrollbar
{
  width: 5px !important;
  display: block !important;
  height: 6px !important;
}
/*定义滚动条轨道 内阴影+圆角*/
/* .scrollbar1::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #F5F5F5;
} */
/*定义滑块 内阴影+圆角*/
.scrollbar1::-webkit-scrollbar-thumb{
  border-radius: 10px !important;
  background-color: #555 !important;
}
.scrollbar2{
    overflow-y: scroll;
    overflow-x: hidden;
}
.scrollbar2::-webkit-scrollbar
{
  width: 0px !important;
  display: block !important;
  height: 3px !important;
}
/*定义滚动条轨道 内阴影+圆角*/
/* .scrollbar1::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #F5F5F5;
} */
/*定义滑块 内阴影+圆角*/
.scrollbar2::-webkit-scrollbar-thumb{
  border-radius: 6px !important;
  background-color: #555 !important;
}
/* Company页面添加Company弹窗样式 */
.AddCompany_input{
    background-color:#ffffff !important;
}
.AddCompany_input::placeholder{
    color: #495057 !important;
    font-weight:500 !important;
}
.AddCompany_textarea::placeholder{
    color: #495057 !important;
    font-weight:500 !important;
}
/* 下拉箭头激活 */
.questionListIconAtive{
    transform:rotate(-90deg) !important;
    transition: all 0.2s
}
/* 下拉箭头还原 */
.questionListIconRestore{
    transform:rotate(90deg) !important;
    transition: all 0.2s
}
/* CSS写的叉号 */
.closeicon{
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}
.closeicon::before,
.closeicon::after {
    position: absolute;
    content: ' ';
    background-color: #495057;
    left: 2px;
    top: 2px;
    width: 2px;
    height: 16px;
    cursor: pointer;
}
.closeicon:hover::before{
    background-color: #007bff;
}
.closeicon:hover::after{
    background-color: #007bff;
}
.closeicon::before {
    transform: rotate(45deg);
}
.closeicon::after {
    transform: rotate(-45deg);
}

/* 元素设置为disabled时颜色修改 */
.disabledcolor:disabled{
    background-color: #4d4d4d !important;
    border-color:  #4d4d4d !important;
}
/* 改变弹窗尺寸 */
.modal-sm{
    max-width:466px !important;
}
/* 对号样式 */
.iconCheck{
    width: 1.5rem;
    height: 1rem;
    border-bottom: 4px solid;
    border-left: 4px solid;
    transform: rotate(-45deg);
    display: inline-block;
    border-color: #07af07;
    border-radius: 3px;
}
/* 文字内容禁止选中 */
.textDisable{
    user-select: none; /* 不可复制 */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
}
.loadingBG{
    position: absolute;
    inset: 0;
    z-index: 99;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgba(255,255,255,0.7);
}
/* select插件样式修改 */
.select__indicator-separator{
    display: none !important;
}
.select__placeholder{
    color: #495057 !important;
    overflow: hidden;
    white-space: nowrap;
}
/* 组件弹框层级修改 */
.modal{
    z-index: 100 !important;
}
.modal-backdrop{
    z-index: 100 !important;
}
.disableCard{
    cursor:not-allowed;
    pointer-events:none;
    filter: grayscale(100%);
}