.textareaStyle{
    background: #E2E2E3;
    border: none;
    resize:none;
    border-radius: 5px;
}
.textareaStyle::placeholder{
    color: #23272B !important;
    text-align: center;
    font-weight: 600;
}