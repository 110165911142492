	.infusion-form{
		width: 100%;
		margin: 0 auto;
		font-size: 14px;
	}
	.infusion-field{
		display: flex;
		justify-content: space-between;
		margin-bottom: 7px;
	}
	.infusion-field label{
		line-height: 40px;
	}
	.form_input{
		background-color: #E2E2E3;
		border-radius: 6px !important;
		border: 1px solid #ddd !important;
		box-sizing: border-box !important;
		padding: 8px 16px !important;
		height: 40px !important;
		line-height: normal !important;
		outline: none !important;
		width: 100%;
	}
	.form_input::placeholder{
		color: #23272B;
	}
	.infusion-option{
		display: block;
	}
    input[type="radio"], input[type="checkbox"]{
        margin-right: 1rem;
    }
    /* input::-webkit-input-placeholder {
        color: #aab2bd !important;
    } */
    textarea::-webkit-input-placeholder {
        color: #aab2bd !important;
    }
	.vh-90{
		min-height: 90vh !important;
	}
	.pt-1{
		padding-top:1% !important;
	}
	.pb-1{
		padding-bottom:1% !important;
	}