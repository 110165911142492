.helpcenter-col{

}
.title{
    color:#343A40;
    font-size: 32px;
    font-weight: bold;
}
.Toptitle{
    color: #92929D;
    font-size: 22px;
}
.color_92929D{
    color: #92929D !important;
    margin-bottom: 0;
}
.commoninput{
    background-color: #E2E2E3;
    border-radius: 6px !important;
    border: 1px solid #ddd !important;
    box-sizing: border-box !important;
    padding: 8px 16px !important;
    height: 40px !important;
    line-height: normal !important;
    outline: none !important;
    width: 55%;
}
.commoninput::placeholder{
    color: #23272B;
}
.commonoption{
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}
/* input[type="radio"]{
    display:none;
}
input[type="radio"]+label{
    position:relative;
    padding-left:30px;
    line-height:20px;
    color:#333;
    font-weight:normal;
    margin-left:2px;
}

input[type="radio"]+label::before {
    content:"";
    width:1.1rem;
    height:1.1rem;
    border:1px solid #007BFF;
    position:absolute;
    left:0;
}
input[type="radio"]:checked+label::after {
    top:3px;
    left:3px;
    content:"";
    background-color:#007BFF;
    width:12px;
    height:12px;
    position:absolute;
    vertical-align:middle;
} */
.textareaStyle{
    background: #E2E2E3;
    border: none;
    resize:none;
    border-radius: 5px;
}
.textareaStyle::placeholder{
    color: #23272B !important;
    text-align: center;
}
.sendBtn{
    background: #007BFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #ffffff;
    width: 6rem;
    height: 2rem;
}
.leftContent{
    padding-left:2rem;
    border-left:1px solid #007BFF;
    height: 100%;
}
