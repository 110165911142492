.EmailNotifications_input{
    width: 100%;
    border: 1px solid;
    height: 3rem;
    border-color: #BCE0FD;
    margin-bottom: 1.3rem;
    padding: 0 1rem;
}
.EmailNotifications_input::placeholder{
    color: #BCE0FD;
    font-size:14px;
    padding-left: 1rem;
}
.emailscrollbar{
    background-color: rgb(255, 255, 255);
    max-width: 608px;
    height: 55rem;
    overflow-y: scroll;
    overflow-x: auto;
    padding: 0;
}
.emailscrollbar::-webkit-scrollbar
{
  width: 5px !important;
  display: block !important;
  height: 6px !important;
}
/*定义滚动条轨道 内阴影+圆角*/
.emailscrollbar::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #ffffff;
  border:1px solid #BCE0FD;
}
/*定义滑块 内阴影+圆角*/
.emailscrollbar::-webkit-scrollbar-thumb{
  border-radius: 10px !important;
  background-color: #2699FB !important;
}
.EmailNotifications_option{
    margin-bottom:0.5rem;
    height: 3rem;
    line-height: 3rem;
    cursor: pointer;
}
.EmailNotifications_option:hover{
    background-color: #BCE0FD;
}
.EmailNotifications_tip{
    width: 100%;
    height: 12rem;
    background: #D1ECF1;
    margin-top: 1.3rem;
    border-radius: 5px;
    border: 1px solid;
    padding:1rem 1rem;
    border-color: #BEE5EB;
    overflow-y: scroll;
}
.EmailNotifications_tip::-webkit-scrollbar
{
  width: 2px !important;
  display: block !important;
  height: 3px !important;
}
/*定义滚动条轨道 内阴影+圆角*/
.EmailNotifications_tip::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #ffffff;
  border:1px solid #BCE0FD;
}
/*定义滑块 内阴影+圆角*/
.EmailNotifications_tip::-webkit-scrollbar-thumb{
  border-radius: 6px !important;
  background-color: #2699FB !important;
}
.EmailNotifications_head{
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
.EmailNotifications_head_arrow{
  width: 0;
  height: 0;
  border-top: 8px solid #007bff;
  /* border-bottom: 20px solid white; */
  border-left: 8px solid white;
  border-right: 8px solid white;
  display: inline-block;
  margin-left: 2rem;
  cursor: pointer;
}
.EmailNotifications_head_options{
  width:  20rem;
  border: 0.3rem;
  top: 3rem;
  position: absolute;
  z-index: 99;
}
.EmailNotificationsTemplate_option{
  height: 3rem;
  line-height: 3rem;
  cursor: pointer;
  border-bottom: 1px solid #92929D;
}
.EmailNotificationsTemplateItem_option{
  height: 3rem;
  line-height: 3rem;
  cursor: pointer;
  border-bottom: 1px solid #92929D;
}
.EmailNotificationsTemplateItem_option:hover{
  background-color: #BCE0FD;
}
.EmailNotifications_button{
  width: 105px;
  height: 38px;
  background: #007BFF;
  color: #ffffff;
  border-radius: 5px;
  margin: 0 5px;
}
.EmailNotifications_button_icon{
  display: inline-block;
  width: 15px;
  height: 10px;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: rotate(-55deg);
  margin-bottom: 4px;
  margin-left: 9px;
}